import { Injectable } from '@angular/core';
import { BehaviorSubject, from, map } from 'rxjs';
import { environment } from '../../environments/environment';
import {
    BooleanField,
    GroupField,
    ImageField,
    KeyTextField,
    LinkField, PrismicDocument,
    RichTextField,
    SelectField,
    TitleField
} from '@prismicio/client';

const GLOBAL_CONTENT = 'global-content';

@Injectable({
    providedIn: 'root'
})
export class GlobalContentService {
    private globalContent = new BehaviorSubject<any | undefined>(undefined);

    constructor() {
        this.fetchGlobalContent().subscribe((globalContent) => {
            this.globalContent.next(globalContent);
        });
    }

    getGlobalContent(): BehaviorSubject<any | undefined> {
        return this.globalContent;
    }

    private fetchGlobalContent() {
        return from(environment.prismic.client.getSingle<PrismicDocument<GlobalContent>>(GLOBAL_CONTENT)).pipe(
            map((result) => {
                return result.data;
            })
        );
    }
}

export type GlobalContent = {
    navigation_bar_menu_items: GroupField<MenuItem>;
    navigation_bar_menu_action_items: GroupField<MenuItem>;
    show_top_bar: BooleanField;
    top_bar_text: RichTextField;
    footer_contact_title: TitleField;
    footer_contact_description: RichTextField;
    footer_contact_actions: GroupField<MenuItem>;
    footer_title: TitleField;
    footer_description: RichTextField;
    footer_button_link: LinkField;
    footer_button_text: KeyTextField;
    footer_links_title: KeyTextField;
    footer_links: GroupField<MenuItem>;
    footer_contact_address: KeyTextField;
    footer_contact_email: KeyTextField;
    footer_links_legal: GroupField<MenuItem>;
    footer_social_links: GroupField<SocialLink>;
};

export type MenuItem = {
    title: KeyTextField;
    link: LinkField;
    anchor: KeyTextField;
    style?: SelectField;
};

export type SocialLink = {
    icon: ImageField;
    link: LinkField;
};
