import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterLink } from '@angular/router';
import { GlobalContent } from '../../../services/global-content.service';
import { LinkComponent } from '../link/link.component';

@Component({
    selector: 'app-navigation',
    standalone: true,
    imports: [CommonModule, LinkComponent, RouterLink],
    templateUrl: './navigation.component.html',
    styleUrl: './navigation.component.scss'
})
export class NavigationComponent implements OnInit {
    isCollapsed = false;
    @Input({ required: true }) globalContent?: GlobalContent;

    constructor(@Inject(PLATFORM_ID) private platformId: any) {
    }

    collapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    closeMenu(): void {
        this.isCollapsed = false;
    }

    ngOnInit() {
        this.scrollFunction();
    }

    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.scrollFunction();
    }

    scrollFunction() {
        if (isPlatformBrowser(this.platformId)) {
            const logo = document.getElementById('logo');
            if (logo) {
                if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
                    logo.style.width = '180px';
                } else {
                    logo.style.width = '260px';
                }
            }
        }
    }
}
