import { Component, Input } from '@angular/core';
import { GlobalContent } from '../../../services/global-content.service';
import { RenderHtmlPipe } from '../prismic/render-html/render-html.pipe';
import { HTMLRichTextSerializer } from '@prismicio/client';
import { serializeHyperlink } from '../prismic/richtextSerializer';

@Component({
  selector: 'app-topbar',
  standalone: true,
    imports: [
        RenderHtmlPipe
    ],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss'
})
export class TopbarComponent {
    @Input() globalContent?: GlobalContent;

    htmlSerializer: HTMLRichTextSerializer = {
        paragraph: ({ children }) => '<p class="color-white mb-0">' + children + '</p>',
        strong: ({ children }) => '<span class="color-white fw-bold">' + children + '</span>',
        hyperlink: ({ children, node }) => serializeHyperlink(children, node, 'white')
    };
}
