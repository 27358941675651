import {Component, Inject, inject, OnInit, PLATFORM_ID} from '@angular/core';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { GlobalContent, GlobalContentService } from './services/global-content.service';
import AOS from 'aos';
import {FooterComponent} from "./components/shared/footer/footer.component";
import {NavigationComponent} from "./components/shared/navigation/navigation.component";
import { TopbarComponent } from './components/shared/topbar/topbar.component';
import { DoctolibComponent } from './components/shared/doctolib/doctolib.component';

@Component({
  selector: 'app-root',
  standalone: true,
    imports: [CommonModule, RouterOutlet, FooterComponent, NavigationComponent, TopbarComponent, DoctolibComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{

  globalContent?: GlobalContent;
  private readonly globalContentService = inject(GlobalContentService);

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
  }

  ngOnInit() {
    this.globalContentService.getGlobalContent().subscribe((globalContent) => {
      if (globalContent) {
        this.globalContent = globalContent;
      }

      if (isPlatformBrowser(this.platformId)) {
        AOS.init()
      }

    });
  }
}
