<div
    style="position:fixed;bottom:150px;right:0;display:block;text-align:center;background-color:#107ACA;; color:#ffffff;font-size:14px;width:100px;border-radius:4px 0 0 4px;padding:10px;z-index:999;box-shadow:1px 2px 4px rgba(0,0,0,0.2);line-height:1.4;">

    <a style="display:block;color:#107ACA;text-decoration:none;padding:5px;margin-top:5px;background:#fff;border-radius:4px;"
       href=" https://www.doctolib.de/gemeinschaftspraxis/baunatal/kinderaerztinnen-baunatal/booking?utm_campaign=website-button&amp;utm_source=kinderaerztinnen-baunatal/booking-website-button&amp;utm_medium=referral&amp;utm_content=option-8&amp;utm_term=kinderaerztinnen-baunatal/booking"
       target="_blank" rel="noopener noreferrer">Termin online buchen</a>

    <a style="display:block;color:#107ACA;text-decoration:none;padding:5px;margin-top:5px;background:#fff;border-radius:4px;"
       href=" https://www.doctolib.de/gemeinschaftspraxis/baunatal/kinderaerztinnen-baunatal/patient-request?utm_campaign=website-button&amp;utm_source=kinderaerztinnen-baunatal/patient-request-website-button&amp;utm_medium=referral&amp;utm_content=option-8&amp;utm_term=kinderaerztinnen-baunatal/patient-request"
       target="_blank" rel="noopener noreferrer">Nachricht senden</a>

    <img style="display:inline-block;height:15px;margin-top:10px;vertical-align:middle;width:auto"
         src="https://www.doctolib.de/external_button/doctolib-white-transparent.png" alt="Doctolib" />
</div>
