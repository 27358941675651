<footer class="mt-5 mt-lg-6">
    <img src="./assets/images/kb-footer-dragon.svg" alt="Dragon" class="w-100" />
    <div class="background-green pb-5">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-12 mt-5 mt-lg-0 text-center">
                    <div [innerHTML]="globalContent?.footer_contact_title | renderHtml: htmlSerializer"></div>
                    <div [innerHTML]="globalContent?.footer_contact_description | renderHtml"></div>
                </div>
                <div class="col-auto mt-4 mt-lg-5" *ngFor="let action of globalContent?.footer_contact_actions">
                    <app-link ngSkipHydration [link]="action.link" [cssClass]="getButtonClass(action.style)">
                        @if (action?.link?.url?.includes('tel:')) {
                            <img src="assets/images/phone-call.svg" class="icon-small me-2" alt="Phone">
                        }
                        {{ action.title }}
                    </app-link>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-end">
                <img class="icon-big mt-5" src="assets/images/bird.svg" alt="Vogel">
            </div>
            <hr class="mb-5 mb-lg-6 mt-0" />
            <div class="row mt-5 d-flex justify-content-between">
                <div class="col-lg-6 pe-lg-6 mb-5 mb-lg-0">
                    <div [innerHTML]="globalContent?.footer_title | renderHtml: htmlSerializer"></div>
                    <div [innerHTML]="globalContent?.footer_description | renderHtml"></div>
                    <app-link ngSkipHydration [link]="globalContent?.footer_button_link"
                              cssClass="btn btn-black-filled">{{ globalContent?.footer_button_text }}
                    </app-link>
                </div>
                <div class="col-lg-3 mb-5">
                    <h3>{{ globalContent?.footer_links_title }}</h3>
                    <ul class="mt-5">
                        <li *ngFor="let link of globalContent?.footer_links">
                            <app-link ngSkipHydration [link]="link.link">
                                {{ link.title }}
                            </app-link>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3 mb-5">
                    <h3><a routerLink="/kontakt">Kontakt</a></h3>
                    <div class="my-5">
                        <p class="color-white">{{ globalContent?.footer_contact_address }}</p>
                        <a class="color-white"
                           [href]="'mailto:' + globalContent?.footer_contact_email">{{ globalContent?.footer_contact_email }}</a>
                    </div>
                    <ul>
                        <li *ngFor="let link of globalContent?.footer_links_legal">
                            <app-link ngSkipHydration [link]="link.link">
                                {{ link.title }}
                            </app-link>
                        </li>
                    </ul>
                </div>
            </div>
            <hr />
            <div class="row justify-content-center justify-content-lg-between mt-5">
                <div class="col-10 col-md-auto text-center text-md-start">
                    <p>© {{ year }} Kinderarzt Baunatal</p>
                </div>
                <div class="col-md text-center text-md-end">
                    <div>
                        @if (globalContent?.footer_social_links?.length) {
                            @for (social of globalContent?.footer_social_links; track social; let last = $last) {
                                <app-link ngSkipHydration [link]="social?.link">
                                    <img class="icon-small" [class.me-3]="!last" [src]="social?.icon?.url"
                                         [alt]="social?.icon?.alt" />
                                </app-link>
                            }
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
