<nav class="navbar navbar-expand-xl position-sticky background-beige top-0">
    <div class="container">
        <a class="navbar-brand" routerLink="" (click)="isCollapsed = false">
            <img id="logo" src="./assets/images/Kinderarzt-Baunatal-Logo.svg" alt="Kinderarzt Baunatal" />
        </a>
        <button class="navbar-toggler" type="button" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="collapse()">
            <img [src]="isCollapsed ? '/assets/icons/close.svg' : '/assets/icons/menu.svg'" alt="Menu Icon" />
        </button>
        <div class="collapse navbar-collapse" id="navbarNav" [class.show]="isCollapsed">
            <ul class="navbar-nav d-flex align-items-center ms-auto">
                <li class="nav-item mt-4 mt-xl-0 mx-4" *ngFor="let menuItem of globalContent?.navigation_bar_menu_items">
                    <app-link ngSkipHydration [link]="menuItem.link" [anker]="menuItem?.anchor" (click)="closeMenu()" *ngIf="menuItem.style === 'Link'; else button" [cssClass]="'color-grey'">
                        {{ menuItem.title }}
                    </app-link>
                    <ng-template #button>
                        <app-link ngSkipHydration [link]="menuItem.link" [anker]="menuItem?.anchor" (click)="closeMenu()" [cssClass]="menuItem?.style === 'Button (Primär)' ? 'btn btn-red-filled' : 'btn btn-green-outline'">
                            {{ menuItem.title }}
                        </app-link>
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>
</nav>
